import React from 'react';
import ReactMarkdown from "react-markdown";
import { MotiveDetails } from "../../types/MotiveDetails";

function MotiveGeneralInfo({ details }: { details: MotiveDetails }) {
    
    if (!details) return <></>;
    return (
        <>
            <hr />
            <h2>Id: {details.uid} : {details.name}</h2>
            <hr />
            <h2>Opis:</h2>
            <h3>Krótki:</h3><div>{details.shortDesc}</div>
            <h3>Pełny:</h3><div><ReactMarkdown>{details.fullDesc}</ReactMarkdown></div>
            <br />
            <hr />
        </>
    );
}

export default MotiveGeneralInfo;